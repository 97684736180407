var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('PurchaseInstallments.PurchaseInstallments'),"description":_vm.$t('PurchaseInstallments.FromHereYouCanControlThePurchasePremiums')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('PurchaseInstallments.SearchForAPremium'),"emptyTableText":_vm.$t('PurchaseInstallments.ThereAreNoPurchasePremiums'),"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"buttonRole":_vm.$user.role.purchase_cheques_add,"cloumns":[
                       {column: 'code',    title: _vm.$t('PurchaseInstallments.Premium') ,type: 'mainLink' , sort: true },
                       {column: 'client_type',    title: _vm.$t('PurchaseInstallments.supplierName') ,type: 'clintLink', sort: true},
                       {column: 'safe_id',    title:_vm.$t('PurchaseInstallments.Treasury'),      type: 'link', to:'safe', sort: true, link: true},
                       {column: 'created_date',    title: _vm.$t('PurchaseInstallments.RegistrationDate') ,type: 'text' , sort: true },
                       {column: 'date',    title: _vm.$t('PurchaseInstallments.paymentDate') ,type: 'text' , sort: true },
                       {column: 'description',    title: _vm.$t('PurchaseInstallments.AndThatIsAbout') ,type: 'text' , sort: true },
                       {column: 'cost',    title: _vm.$t('purchaseCheques.amount') ,type: 'text' , sort: true },
                       {column: 'payment_method_id',    title: _vm.$t('PurchaseInstallments.PaymentMethod') ,type: 'link', to:'paymentMethod', sort: true, link: false},
                       {column: 'stat',    title: _vm.$t('PurchaseInstallments.active') ,type: 'status', sort: true,  values: [
                         {title: _vm.$t('PurchaseInstallments.Waiting'), value: 0, color: 'info'},
                         {title: _vm.$t('PurchaseInstallments.driven'), value: 1, color: 'success'} ]},
                       {column: 'options', title: _vm.$t('PurchaseInstallments.measures'), type: 'options', options: [
                           {name: 'show'},
                           {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.purchase_cheques_edit},
                           {name: 'file'},
                           {name: 'pay',   role: _vm.$user.admin || _vm.$user.role.purchase_cheques_edit},
                           {name: 'delete', role: _vm.$user.admin || _vm.$user.role.purchase_cheques_delete} ]}
                     ],"deleteText":{
                      attention: _vm.$t('allerts.Attention'),
                      areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                      close: _vm.$t('allerts.close'),
                      confirm: _vm.$t('allerts.confirm'),
                      loading: _vm.$t('allerts.loading'),
                     }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }